/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link } from 'gatsby';

// Import components
import Layout from 'components/Layout';
import SEO from 'components/SEO';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section style={{ textAlign: 'center', paddingTop: '8rem' }}>
      <h1>Not Found</h1>
      <p>
        Sorry, the page you are looking for has either moved of does not exist.
      </p>
      <Link
        to="/"
        style={{
          color: 'white',
          background: '#0889af',
          padding: '1rem',
          borderRadius: '50px',
        }}
      >
        Return to Home
      </Link>
    </section>
  </Layout>
);

export default NotFoundPage;
